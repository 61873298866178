<template>
  <div>
    <ApplyInfo
      :accApplyPgmMngDetail="accApplyPgmMngDetail"
      :accInfo="accInfo"
      :accCeoInfo="accCeoInfo"
      :accAwardCareerInfo="accAwardCareerInfo"
      :accPropertyInfo="accPropertyInfo"
      :accLicenseInfo="accLicenseInfo"
      :accMemberInfo="accMemberInfo"
      :accStartPgJoinExp="accStartPgJoinExp"
      :accMentorInfo="accMentorInfo"
      :accInvestInfo="accInvestInfo"
    />

    <!-- 버튼 -->
    <div class="btn-wrap type2">
      <BackButton
        @click="$emit('back')"
      />
      <BaseButton
        type="line"
        @click="$emit('save')"
      >{{$t('content.common.button.btn01')}}</BaseButton>
      <BaseButton
        type="line"
        @click="doPrev"
      >{{$t('content.common.button.btn03')}}</BaseButton>

      <!-- 지원하기 -->
      <BaseButton
        @click="$emit('submit')"
      >{{$t('content.accelerating.academy.Common.txt01')}}</BaseButton>
    </div>
    <!-- // 버튼 -->
  </div>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseButton from '@/components/base/BaseButton.vue';
import BackButton from '@/components/base/BackButton.vue';
import ApplyInfo from '../component/ApplyInfo.vue';

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭) - 6단계 : 지원서확인
 */
export default {
  name: 'ProgramsApplyStep6',
  mixins: [mixinHelperUtils],
  components: {
    ApplyInfo,
    BaseButton,
    BackButton,
  },
  props: {
    /**
     * Acc지원프로그램 관리 상세
     * @type {import('../dto/AccApplyPgmMngDetailDto').default}
     */
    accApplyPgmMngDetail: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 지원정보 (회사정보, 서비스, 기타입력 등)
     * @type {import('../dto/apply/AccInfoDto').default} AccInfoDto
     * @see AccApplyDto
     */
    accInfo: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 대표자, 공동대표
     * @type {import('../dto/apply/AccCeoInfoDto').default[]}
     * @see AccApplyDto
     */
    accCeoInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 수상경력
     * @type {import('../dto/apply/AccAwardCareerInfoDto').default[]}
     * @see AccApplyDto
     */
    accAwardCareerInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 보유 지적 재산권
     * @type {import('../dto/apply/AccPropertyInfoDto').default[]}
     * @see AccApplyDto
     */
    accPropertyInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 보유 허가 및 등록 현황
     * @type {import('../dto/apply/AccLicenseInfoDto').default[]}
     * @see AccApplyDto
     */
    accLicenseInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 멤버
     * @type {import('../dto/apply/AccMemberInfoDto').default[]}
     * @see AccApplyDto
     */
    accMemberInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 창업 프로그램 참여경험
     * @type {import('../dto/apply/AccStartPgJoinExpDto').default[]}
     * @see AccApplyDto
     */
    accStartPgJoinExp: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 멘토
     * @type {import('../dto/apply/AccMentorInfoDto').default[]}
     * @see AccApplyDto
     */
    accMentorInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 투자유치 현황
     * @type {import('../dto/apply/AccInvestInfoDto').default[]}
     * @see AccApplyDto
     */
    accInvestInfo: {
      type: Array,
      required: true,
    },
  },

  methods: {
    doPrev() {
      this.$router.push({
        name: 'ProgramsApplyStep5',
      });
    },
  },
};
</script>
